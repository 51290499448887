<template>

    <div class="w-fit flex items-center gap-2 cursor-pointer select-none" :class="[variant, size]">

        <div class="checkbox" :class="['relative aspect-square flex items-center gap-2 justify-center border-2 rounded-sm cursor-pointer shrink-0', {'active' : this.modelValue}]">

            <i v-if="this.modelValue" class="icon-check absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"/>

        </div>

        <p class="text-main-100 text-xs" v-if="this.text">{{ this.text }}</p>

    </div>

</template>

<script>
import BaseInputComponent from './BaseInputComponent'

export default {

    extends: BaseInputComponent,

    props: { size: { type: String, default: 'sm' }, variant: { type: String, default: 'default' } }

}
</script>

<style scoped>

.lg .checkbox { height: 22px }
.lg .icon-check { font-size: 14px }
.md .checkbox { height: 20px }
.md .icon-check { font-size: 12px }
.sm .checkbox { height: 18px }
.sm .icon-check { font-size: 12px }
.xs .checkbox { height: 16px }
.xs .icon-check { font-size: 10px }

.default {

    color: theme('colors.main.100');
}

.default .checkbox {

    border-color: theme('colors.main.100');
}

.default .checkbox.active {

    background: theme('colors.second.10');
}

.light {

    color: theme('colors.main.5');
}

.light .checkbox {

    border-color: theme('colors.main.5');
}

.light .checkbox.active {

    background: theme('colors.second.100');
}
</style>