<template>

    <Teleport to="body">

        <div class="fixed z-[500] outline-none animate-dropdown" :style="this.style" ref="element">

            <slot id="slot"/>

        </div>

    </Teleport>

</template>

<script>

export default {

    props: {

        fixed: { type: Object, default: () => { return { top: 0, left: 0 } } },
        translateX: { type: Boolean, default: false },
        translateY: { type: Boolean, default: false },
        centerX: { type: Boolean, default: false },
        centerY: { type: Boolean, default: false },
        stretch: { type: Boolean }
    },

    data: function() {

        return {

            anchorElement: undefined,
            element: undefined,
            style: { transition: 'top 50ms, bottom 50ms, left 50ms, right 50ms' },
        }
    },

    unmounted() { clearInterval(this.interval) },
    
    async mounted() {

        clearInterval(this.interval)

        this.element = this.$refs.element
        this.anchorElement = this.$el.parentElement

        //this.ListenAnchorElementBlurEvent()

        this.interval = setInterval(() => this.CalculatePosition(), 100)
    },

    methods: {

        CalculatePosition: function() {

            this.anchorRect = this.anchorElement.getBoundingClientRect()
            this.elementRect = this.element.getBoundingClientRect()

            if ( this.stretch ) { this.element.style.width = this.anchorRect.width + 'px' }

            this._fixed = { ...this.fixed }

            Object.keys(this._fixed).forEach(pos => {

                if ( ['top', 'bottom'].includes(pos) ) { this.style.top = this.GetYAxisPosition(pos) + 'px' }
                else if ( ['left', 'right'].includes(pos) ) { this.style.left = this.GetXAxisPosition(pos) + 'px' }
            })
        },

        GetYAxisPosition: function(offset) {

            if ( this.centerY ) { return this.anchorRect.top + (this.anchorRect.height / 2) - (this.elementRect.height / 2) }

            if ( offset === 'top' ) {

                const position = this.anchorRect.top + this._fixed.top
                const top = this.translateY ? position + this.anchorRect.height : position
                
                if ( (top + this.elementRect.height) > window.innerHeight ) {
                    
                    this._fixed.bottom = this._fixed.top
                    delete this._fixed.top

                    return this.GetYAxisPosition('bottom')
                }

                return top
            }

            if ( offset === 'bottom' ) {

                const position = this.anchorRect.bottom - this.elementRect.height - this._fixed.bottom
                return this.translateY ? position - this.anchorRect.height : position
            }
        },

        GetXAxisPosition: function(offset) {

            if ( this.centerX ) { return this.anchorRect.left + (this.anchorRect.width / 2) - (this.elementRect.width / 2) }

            if ( offset === 'left' ) {

                const position = this.anchorRect.left + this._fixed.left
                return this.translateX ? position + this.anchorRect.width : position
            }

            if ( offset === 'right' ) {

                const position = this.anchorRect.right - this.elementRect.width - this._fixed.right
                return this.translateX ? position - this.anchorRect.width : position
            }
        }
    },

}

</script>