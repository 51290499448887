<template>

    <div
        class="fixed inset-0 flex justify-center gap-2 bg-main-100/70 pt-12 pb-4 px-2 lg:px-10 z-50 text-main-100"
        :class="{'contents': contents}"
        @click="this.computedBully ? undefined : this.computedOnclose?.()"
        v-bind="currentSlot"
        title="">        

        <div @click.stop @dblclick.stop v-if="rawSlot" class="contents"> <slot :name="activeSlot"/> </div>

        <div v-else class="relative animate-popup h-fit max-h-full max-w-full flex" @click.stop @dblclick.stop>

            <div
                class="modal-container max-h-full max-w-full flex flex-col gap-6 overflow-y-auto scroll-hidden"
                :class="[{ 'bg-main-5 shadow-material rounded-xl p-6': this.defaultStyle }, {'w-[560px]': fixedWidth}, { 'overflow-hidden': !this.overflow }]"
                @click.stop @dblclick.stop @mousedown.stop>

                <div class="flex flex-col gap-1" v-if="currentTitle || currentText">

                    <div class="flex items-center gap-3">

                        <i v-if="currentHeaderIcon" class="cursor-pointer" v-bind="currentHeaderIcon"/>
                        <p class="flex items-center gap-2 text-lg font-bold text-start" v-html="currentTitle"/>

                    </div>

                    <p class="leading-relaxed" v-if="currentText" v-html="currentText"/>
                
                </div>

                <div v-if="currentContent" v-html="currentContent"/>

                <div class="[&>*]:max-w-full contents">

                    <slot v-if="activeSlot" :name="activeSlot"/>
                    <slot v-else/>

                </div>

                <div v-if="currentFooter?.length" class="flex flex-wrap items-center gap-3 mt-3" :class="currentFooter?.length > 1 ? 'justify-between' : 'justify-end'">

                    <Button v-for="button in currentFooter" :key="button" v-bind="button" :loading="button.loading" onclick="" @click="this.HandleClick(button)" :class="{'mx-auto': button.center}"/>

                </div>

            </div>
                
            <i
                v-if="!this.hideCloseButton && this.computedOnclose"
                class="icon-cross-rounded lg:text-white text-3xl absolute top-2 right-2 lg:top-0 lg:-right-[48px] text-main-100 h-10 w-10 hover:rotate-90 transition-[transform,top,right,color] cursor-pointer active:scale-95"
                @click="this.computedOnclose"/>

        </div>

    </div>

</template>

<script>


export default {

    props: {

        onclose: { type: Function },
        hideCloseButton: Boolean,
        bully: { type: Boolean, default: true },
        overflow: { type: Boolean, default: true },
        defaultStyle: { type: Boolean, default: true },
        contents: { type: Boolean },
        title: String,
        text: String,
        content: String,
        footer: Array,
        fixedWidth: Boolean,
        slotsConfig: Object
    },

    computed: {

        slots() { console.log(this.$slots);return Object.entries(this.$slots) },

        activeSlot() { return this.slotsConfig?.activeSlot },
        
        currentSlot() { return this.slotsConfig ? this.slotsConfig[this.activeSlot] : undefined },
        
        rawSlot() { return this.currentSlot?.raw },

        currentHeaderIcon() { return this.currentSlot?.headerIcon || this.headerIcon },

        currentTitle() { return this.ProcessText(this.currentSlot?.title || this.title) },

        currentText() { return this.ProcessText(this.currentSlot?.text || this.text) },

        currentContent() { return this.ProcessText(this.currentSlot?.content || this.content) },

        currentFooter() { return this.currentSlot?.footer || this.footer },

        computedBully() { return !this.hideCloseButton && (this.currentSlot?.bully ?? this.bully) },

        computedOnclose() { return this.currentSlot?.onclose || this.slotsConfig?.onclose || this.onclose },

        slotsConfig_() { return this.slotsConfig ?? {} }
    },

    created() {

        if ( this.slotsConfig ) { this.slotsConfig_.RenderSlot = this.RenderSlot }
    },

    methods: {

        ProcessText: function(text) {

            if ( typeof text === 'function' ) return text()

            return text
        },

        RenderSlot: function(to) {

            if ( !this.slotsConfig ) return

            if ( typeof to === 'string' ) { return this.slotsConfig_.activeSlot = to }

            const index = this.slots[0].indexOf(this.activeSlot)
            
            if ( index === -1 || (to === 1 && index === this.slots[0].length - 1) || (to === -1 && index === 0) ) return

            this.slotsConfig_.activeSlot = this.slots[0][index + to]
        },

        HandleClick: async function(button) {

            const action = button.click || button.onclick || button.callback

            if ( !action ) return

            button.loading = true
            await action()
            button.loading = false

            if ( button.afterClick ) button.afterClick()
        }
    }
}
</script>

<style>
.modal-container:has(.overflow-y-scroll:last-child), .modal-container:has(.overflow-y-auto:last-child) {
    padding-bottom: 0;
}

.modal-container .overflow-y-scroll:last-child, .modal-container .overflow-y-auto:last-child {
    padding-bottom: 24px;
}
</style>