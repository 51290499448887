<template>

    <div class="flex flex-col gap-8 bg-white p-4 rounded-md shadow-weak_material text-xs">

        <div v-for="filter in props.config" :key="filter" class="flex flex-col gap-3">

            <div class="flex items-center justify-between border-b border-main-100/20 pb-1">

                <p class="font-semibold">{{ filter.label }}</p>

                <i class="icon-trash text-lg text-main-75 cursor-pointer hover:text-main-100 transition-colors" @click="filter.clear(); filter.onClear?.()"/>

            </div>
            
            <div class="flex gap-2">

                <div class="w-full max-w-[120px] flex flex-col gap-1 text-main-75 text-xs">

                    <p>{{ $st('general.from') }}</p>
                    <input type="date" class="bg-transparent border border-main-50 rounded p-2" v-model="filter.value[0]">

                </div>

                <div class="w-full max-w-[120px] flex flex-col gap-1 text-main-75 text-xs">

                    <p>{{ $st('general.to') }}</p>
                    <input type="date" class="bg-transparent border border-main-50 rounded p-2" v-model="filter.value[1]">

                </div>

            </div>

        </div>

        <slot/>

    </div>

</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({ config: Object })

props.config.forEach(f => {

    switch(f.type) {

        case 'date-range':

            f.clear = () => f.value = []
            
            if ( !f.value ) { f.clear() }
            if ( f.key ) { f.toQuery = () => `${f.key}=${(f.value?.[0])??''}..${(f.value?.[1])??''}` }

            break

        default:
            
            f.clear = () => f.value = undefined
            f.clear()
            if ( f.key ) { f.toQuery = () => `${f.key}=${f.value}` }

            break
    }
})
</script>