<template>

    <div
        class="relative group relative flex items-center justify-center aspect-square cursor-pointer transition-[background]"
        :class="[
            {'!text-main-5 font-medium': this.state.selected},
            {'text-main-50': this.adjacent}]"

        @mouseenter="this.mouseOverDay = true"
        @mouseleave="this.Delay(() => this.mouseOverDay = false)">

        <p class="z-10 text-2xs">{{ this.day }}</p>

        <div
            v-if="this.state.between"
            class="absolute inset-y-[12%] inset-x-0 bg-second-10 transition-[background,inset,border-radius]"
            :class="[{'left-1/2 !w-1/2': this.state.start}, {'right-1/2 !w-1/2': this.state.end}, {'group-hover:inset-[8%] group-hover:rounded': !this.state.selected}]"></div>

        <div
            class="absolute inset-[8%] rounded group-hover:bg-second-10 transition-colors"
            :class="[
                {'!bg-second-100': this.state.selected},
                {'border border-second-10': this.state.today && !this.state.between},
                {'bg-second-10/50': this.state.interval}
            ]">

            <i class="absolute icon-refresh text-sm -top-1 -right-1 text-second-100" v-if="this.state.interval"></i>
        </div>

        <div
            v-if="this.events?.length"
            class="absolute top-2 right-2 rounded-full bg-second-100 p-0.5 animate-pulse"
            :class="{'!bg-cta2-100': this.state.selected}"/>

        <FloatingElement
            :position="{ top: 0, right: -2, translateX: true, centerY: true }"
            v-if="this.events?.length && (this.mouseOverDay || this.mouseOverEvent)">
            
            <div
                class="max-h-[300px] max-w-[260px] flex flex-col gap-1 p-1 rounded bg-white shadow-material overflow-y-auto scroll scroll-main"
                @mouseenter="this.mouseOverEvent = true"
                @mouseleave="this.mouseOverEvent = false">
                
                <div
                    v-for="event in this.events" :key="event"
                    class="flex flex-col gap-0.5 py-1 px-2 rounded hover:bg-main-5 border border-main-5 cursor-pointer overflow-hidden shrink-0"
                    @click="this.$openExternal(event.href)">
                    
                    <div class="text-xs" :class="{'text-main-50': !event.summary}">{{ event.summary ?? 'Title' }}</div>
                    <div class="text-main-75 text-2xs" :class="{'text-main-50': !event.description}" v-html="event.description ?? 'Description'"></div>

                </div>

            </div>

        </FloatingElement>
        
    </div>

</template>

<script>
import FloatingElement from '@/components/FloatingElement'

export default {

    props: ['day', 'state', 'adjacent', 'events'],

    components: { FloatingElement },

    data: function() {

        return {

            mouseOverDay: false,
            mouseOverEvent: false
        }
    },

    methods: {

        Delay: function(callback) { setTimeout(callback, 0) }
    }
}
</script>