<template>

    <div class="toggle" :class="[{'active' : modelValue}, {'loading' : loading}]">

        <div class="mark [.loading_&]:animate-spin"/>

    </div>

</template>

<script>
import BaseInputComponent from './BaseInputComponent.vue'

export default {

    extends: BaseInputComponent,

    props: { loading: Boolean },
}
</script>

<style scoped>
@tailwind base;
@tailwind utilities;

.toggle {
    position: relative;
    width: 32px;
    height: 16px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    @apply bg-main-50/80;
    box-shadow: theme('boxShadow.weak_material');
    border-radius: 32px;
    cursor: pointer;

    transition: border-color 0.2s, opacity 0.3s;
}

.toggle .mark {
    position: absolute;
    height: 12px;
    aspect-ratio: 1/1;
    left: 2px;
    background: #FFF;
    border-radius: 50%;

    transition: inset 200ms, background 200ms;
}

.toggle.active {
    background: theme('colors.cta.100');
}

.toggle.active .mark {
    left: calc(100% - 14px);
}

.toggle.loading .mark {
    background: transparent;
    border: 2px solid theme('colors.main.60');
    border-top-color: white;
}

.toggle.active.loading .mark {
    background: transparent;
    border: 2px solid theme('colors.cta.75');
    border-top-color: white;
}
</style>