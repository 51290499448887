<template>

    <Transition @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @before-leave="beforeLeave" @leave="leave" @after-leave="afterLeave">

        <div v-if="!self" v-show="!collapsed" class="overflow-hidden">

            <slot/>

        </div>

        <slot v-else/>

    </Transition>

</template>

<script>

export default {

    props: {

        collapsed: { type: Boolean, default: true },
        duration: { type: String, default: '250' },
        ease: { type: String, default: 'ease' },
        fade: { type: Boolean },
        self: Boolean
    },

    methods: {

        beforeEnter: function(el) { // eslint-disable-line

            this.$nextTick(() => {

                this.scrollHeight = el.scrollHeight

                el.style.opacity = '0'
                el.style.height = '0'
            })
        },

        enter: function(el) {

            this.$nextTick(() => {

                let borderWidth = parseFloat(window.getComputedStyle(el).borderWidth)

                if ( isNaN(borderWidth) ) { borderWidth = 0 }
                else borderWidth *= 2

                el.style.transition = `height ${this.duration}ms ${this.ease}, opacity ${this.duration}ms`
                el.style.opacity = '1'
                el.style.height = this.scrollHeight + borderWidth + 'px'
            })
        },

        afterEnter: function(el) { // eslint-disable-line
            
            el.style.height = 'auto'
        },

        beforeLeave: function(el) {
            
            el.style.opacity = '1'
            el.style.height = el.scrollHeight + 'px'
        },

        leave: function(el) {

            el.style.transition = `height ${this.duration}ms ${this.ease}, opacity ${this.duration}ms`
            el.style.opacity = '0'
            el.style.height = '0'
        },

        afterLeave: function(el) {
            
            el.style.height = 'auto'
        }
    }
}

</script>