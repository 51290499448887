<template>

    <Modal v-if="open" :slotsConfig="modalSlots">

        <template #modal>

            <div class="h-fit max-h-full w-full max-w-[1200px] flex gap-3 bg-main-10 rounded-3xl animate-popup shadow-material">

                <div class="w-full flex bg-main-5 rounded-3xl shrink-[0.75] shadow-weak_material overflow-hidden"> <!----- Automations ----->

                    <div class="relative w-full flex flex-col gap-6">

                        <div class="w-full flex items-center gap-2 justify-between p-6 pb-0">

                            <p class="text-lg font-medium">Robots programados</p>

                            <div v-if="!ReadonlyMode" class="flex items-center justify-center" :title="executionInProgress ? 'Los procesos se lanzarán automáticamente' : 'No hay nada que lanzar'">

                                <div v-if="executionInProgress" class="absolute h-9 w-9 loading-spinner-outlined_secondary"/>
                                <i class="icon-play-rounded text-second-100 text-4xl" :class="{'!text-main-50': !executionInProgress}"/>

                            </div>

                            <v-card v-else loading class="bg-transparent shadow-none pt-2 px-1 text-2xs">

                                <p>Esperando automatizaciones</p>
                                
                            </v-card>

                        </div>

                        <div v-if="!SomeTask" class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex items-center justify-center gap-3 text-main-50 font-light">
                                
                            <i class="icon-less-rounded text-2xl"/>
                            No tienes ningún proceso automatizado
                        
                        </div>

                        <div class="min-h-[250px] w-full flex flex-col gap-10 overflow-y-auto scroll-main p-6 pt-0">

                            <div v-for="group, key in TasksByExecutionDateGroup" :key="group" class="w-full flex flex-col gap-6">

                                <div class="flex items-center gap-3 text-sm text-main-60">

                                    <div class="h-px w-full bg-main-100/15"/>

                                    <p class="whitespace-nowrap font-light">{{ GetTasksGroupName(key) }}</p>

                                    <div class="h-px w-full bg-main-100/15"/>

                                </div>

                                <div class="w-full flex flex-col gap-3">

                                    <div
                                        v-for="task in group" :key="task"
                                        class="w-full flex gap-3 items-center text-xs animate-popup"
                                        :class="{'!opacity-60': !ReadonlyMode && !task.app.active}">

                                        <div
                                            class="bg-second-100 rounded-2xl py-1 px-3 text-main-5 shadow-weak_material"
                                            :class="[{'!bg-transparent !shadow-none': task.done}, {'!bg-main-50': !task.app.active}]">
                                            
                                            <i v-if="task.done" class="icon-check-rounded text-main-100 text-3xl"/>
                                            <p v-else>{{ $formatDate({ date: task.scheduledDate, format: 'hh:mm' }) }}</p>
                                        </div>

                                        <v-card class="group h-fit w-full flex flex-col rounded-lg bg-second-5 border border-second-10 shadow-weak_material" :loading="task.executionInProgress">

                                            <div class="flex flex-col gap-4 p-4"> <!-- Header -->

                                                <div class="flex items-center justify-between gap-4">
                                            
                                                    <div class="flex items-center gap-4">

                                                        <i v-if="task.app?.icon" :class="task.app.icon" class="text-2xl"/>
                                                        <img v-if="task.app?.image" :src="task.app.image" alt="" class="h-6 w-6">

                                                        <div>

                                                            <p>{{ task.title ?? task.app?.title }}</p>
                                                            <p class="text-main-50">{{ task.description }}</p>

                                                        </div>
                                                        
                                                    </div>

                                                    <v-btn
                                                        v-if="task.canBeRemoved && !task.executionInProgress"
                                                        class="opacity-0 group-hover:opacity-100"
                                                        icon="icon-trash"
                                                        variant="plain"
                                                        :loading="task.removing"
                                                        :disabled=task.executionInProgress
                                                        v-bind="$vuetifyBinding({ size: 'sm' })"
                                                        @click="DeleteFlowSchedule(task)"/>

                                                </div>

                                                <div v-for="info in task.info" :key="info" class="flex items-center justify-between flex-wrap">

                                                    <div class="w-fit flex items-center gap-2 py-1 px-2 rounded bg-second-100/5 border border-second-100/5 text-xs" :title="info.tooltip">
                                                                    
                                                        <i class="text-sm" :class="info.icon"/>
                                                        {{ info.text }}
                                                    
                                                    </div>

                                                </div>

                                            </div>

                                            <div v-if="task.repeat && task.every" class="contents">

                                                <div class="relative h-px w-full bg-second-10"/>

                                                <div class="flex items-center gap-2 p-4">

                                                    <i class="icon-calendar text-lg"/>
                                                    Se lanza cada {{ task.every }} {{ GetScheduleInterval(task.interval, task.every) }}

                                                </div>

                                            </div>

                                            <div v-if="task.items?.length" class="contents">

                                                <div class="relative h-px w-full bg-second-10"/>

                                                <div class="flex flex-col gap-1 p-4" :class="{'lessthan': task.items?.length < 3}">

                                                    <div
                                                        v-for="item in task.items" :key="item"
                                                        class="flex items-center gap-2 [.lessthan_&]:!bg-transparent [.lessthan_&]:!px-0 odd:bg-second-100/5 even:bg-second-50/5 py-1 px-2 rounded">

                                                        <i v-if="item.leadingIcon" :class="item.leadingIcon"/>

                                                        <div v-if="item.dot" class="h-1 w-1 shrink-0 bg-current rounded-full"/>
                                                        
                                                        <p>{{ item.text }}</p>

                                                        <i v-if="item.icon" :class="item.icon" class="ml-auto"/>

                                                    </div>

                                                </div>

                                            </div>

                                        </v-card>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div v-if="!ReadonlyMode" class="w-full flex rounded-3xl overflow-hidden"> <!----- Configuration ----->

                    <div class="w-full flex flex-col gap-6">

                        <p class="text-lg font-medium p-6 pb-0">Control de aplicaciones</p>

                        <div class="w-full flex flex-col gap-3 overflow-y-auto scroll-main p-6 pt-0">

                            <div
                                v-for="app in apps" :key="app"
                                class="h-fit flex flex-col rounded-lg bg-second-5 shadow-weak_material cursor-pointer text-xs">

                                <div class="flex flex-col p-4 gap-4" @click="app.enabled = !app.enabled">
                            
                                    <div class="flex items-center gap-4">

                                        <i v-if="app.icon" :class="app.icon" class="!text-2xl"/>
                                        <img v-if="app.image" :src="app.image" alt="" class="h-6 w-6">

                                        <div>

                                            <p>{{ app.title }}</p>
                                            <p class="text-main-50">{{ app.description }}</p>

                                        </div>

                                        <div class="flex items-center gap-3 ml-auto">
                                            
                                            <div v-if="app.automation && app.enabled && app.timer">

                                                <i v-if="app.timer.isPaused" class="icon-lock text-lg text-main-50"/>
                                                <v-progress-circular v-else :model-value="((refreshInterval - app.timer.remaining) / refreshInterval) * 100" :size="16" :width="2" color="second-100"></v-progress-circular>

                                            </div>

                                            <Toggle v-if="!app.toggle" :modelValue="app.enabled"/>
                                            
                                        </div>
                                        
                                    </div>

                                    <div v-if="app.automation && app.enabled && apps.general.enabled && !app.active" class="flex items-center gap-3 text-cta-100 ml-auto animate-buzz">

                                        <i class="icon-certificate text-lg"/>
                                        <p>Añade un certificado para activar esta función</p>

                                    </div>

                                </div>

                                <div v-if="app.certsConfig" class="contents">

                                    <div class="relative h-px w-full bg-second-10"/>

                                    <div class="flex flex-col p-4 gap-4">

                                        <Button
                                            size="xs"
                                            variant="secondary"
                                            text="Añadir certificado"
                                            icon="icon-certificate"
                                            class="ml-auto"
                                            @click.stop="AddCertificate(app)"/>

                                        <div class="flex flex-col gap-1" v-if="app.certificates?.length">

                                            <div v-for="cert in app.normalizedCertificates" :key="cert" class="flex items-center gap-3 py-1 p-2 bg-second-10/20 border border-second-10/50 rounded">

                                                <i class="icon-certificate text-lg"/>
                                                <p>{{ cert.subject.CN }}</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                            
                        </div>
                        
                    </div>
                    
                </div>

            </div>

        </template>

        <template #certificate>

            <UserResourcesManager 
                :searchInputField="false"
                :scrollLimit="false"
                :shared="false"
                :persistentSelection="false"
                :selectable="false"
                v-bind="modalSlots.selectedApp.certsConfig"
                v-model="modalSlots.selectedCerts"/>

        </template>

    </Modal>

    <v-badge location="top start" :color="Tasks.length ? 'cta-75' : 'transparent'" :content="Tasks.length ? Tasks.length : ''" class="fixed bottom-3 right-3 flex z-50">

        <div
            class="h-full w-full flex gap-3 items-center justify-center p-3 border-[3px] border-cta-50 rounded-3xl shadow-material text-main-5 bg-cta-100 cursor-pointer hover:border-cta-10 shrink-0 transition-colors"
            @click="this.ButtonBinding.onclick">

            <div class="h-5 w-5 flex items-center justify-center"> <i :class="this.ButtonBinding.icon"/> </div>
            <p class="text-xs">{{ this.ButtonBinding.text }}</p>

        </div>

    </v-badge>

</template>

<script>
import Modal from '@/slango-multiverse/components/Modal'
import UserResourcesManager from '@/components/UserResourcesManager'
import NotificationsDashboard from '@/views/NotificationsDashboard'
import Button from '@/slango-multiverse/components/inputs/Button'
import Toggle from '@/slango-multiverse/components/inputs/Toggle'

import { flows } from '@/helpers/APIconnection'
import { NotificationsController } from '@/views/NotificationsDashboard'
import { FlowsController } from '@/views/FlowsPanel'
import { AltasBajasController } from '@/views/Asesoria/AltasBajasDashboard'
import FIEController from '@/helpers/fie-controller.js'

import store from '@/store'

export const LocalFlows = {

    'get-fie-siltra': {

        title: 'Obtener archivos FIE desde SILTRA',
        localFlow: FIEController.GetFieFromSILTRA,
        automationResponse: () => { store.state.FIE_RemoteUpdate?.() }
    },

    'sync-fie-a3': {

        title: 'Sincronización de movimientos FIE en A3',
        localFlow: FIEController.SyncA3,
        automationResponse: () => { store.state.FIE_RemoteUpdate?.() }
    },

    'sync-siltra-a3': {

        title: 'Sincronizar en A3 los FIEs obtenidos desde SILTRA',
        localFlow: FIEController.SyncSILTRAA3,
        automationResponse: () => { store.state.FIE_RemoteUpdate?.() }
    },

    'sync-altas': {

        title: 'Sincronizar formularios de Alta en Sistema Red',
        localFlow: AltasBajasController.SyncSubmissions
    },

    'sync-altas-a3': {

        title: 'Sincronizar formularios de Alta en A3',
        localFlow: AltasBajasController.SyncSubmissionsToA3
    }
}

export default {

    components: { Modal, UserResourcesManager, NotificationsDashboard, Toggle, Button }, // eslint-disable-line

    data: function() {

        return {

            open: false,
            loading: {},
            refreshInterval: 20,

            modalSlots: {
                
                activeSlot: 'modal',
                modal: {
                    raw: true,
                    bully: false,
                    onclick: () => this.$cancelableTimeout(this, 'dblclick-autopilot', 200, () => this.$createToastMessage({ text: 'Haz doble click fuera para salir', type: 'secondary', close: false, fixed: true, alive: 2000 })),
                    ondblclick: () => { this.$cancelableTimeout(this, 'dblclick-autopilot', 1000); this.open = false } }
            },

            apps: {

                general: {

                    title: 'Activar / Desactivar todas',
                    icon: 'icon-desktop',
                    isApp: false,
                    enabled: true
                },
                robots: {

                    title: 'Robots para asesorías',
                    description: 'Robots para asesorías y lanzamientos remotos',
                    icon: 'icon-flow',
                    automation: true,
                    enabled: true,
                    onActive: this.GetFlowSchedules,
                    onUpdate: this.GetFlowSchedules
                },
                altasbajas: {

                    title: 'Altas desasistidas',
                    description: 'Gestión de solicitudes de altas desasistidas',
                    image: require('@/assets/icons/altasbajas.svg'),
                    automationID: 'altasbajas-automation',
                    automation: true,
                    certsConfig: { multiple: false },
                    onActive: this.GetAltasBajasForms,
                    onUpdate: this.GetAltasBajasForms
                },
                notifications: {

                    title: 'Notificaciones',
                    description: 'Comprobación de nuevas notificaciones en DEHÚ',
                    icon: 'icon-bell',
                    automationID: 'notifications-automation',
                    automation: true,
                    onActive: this.GetNotificationsSchedules,
                    certsConfig: { multiple: true }
                }
            },

            tasks: {},
            executionTimer: this.$timer({ interval: 3, callback: this.LaunchNextActiveTask, autoRestart: true }),
            executionInProgress: false, // Active flow launching

            today: undefined,
        }
    },

    created() {

        if ( localStorage.getItem('is-autopilot-open') ) { this.open = true }

        store.state.AutopilotGetSchedulesNow = () => {

            if ( this.apps.robots.active ) { this.apps.robots.timer?.trigger() }
        }

        const getToday = () => {
            
            const today = new Date()
            today.setHours(0, 0, 0, 0)
            this.today = today.getTime()
        }
        
        getToday()
        
        this.todayInterval = setInterval(getToday, 1000)

        if ( this.ReadonlyMode ) { this.apps.robots.enabled = true }
    },

    unmounted() {

        this.Apps?.forEach(app => app.timer?.stop() )
        this.executionTimer.stop()
        clearInterval(this.todayInterval)
    },

    computed: {

        ButtonBinding() {

            return store.state.externalAccess = {

                text: this.ReadonlyMode ? 'Robots programados' : 'Piloto automático',
                icon: this.open ? 'icon-cross !text-3xl flex items-center justify-center' : 'icon-mr-robot text-lg',
                onclick: () => this.open = !this.open
            }
        },

        ReadonlyMode() { return !this.$embedded },

        Apps() { return Object.values(this.apps).filter(app => app.automation) },

        ActivesApp() {
            
            return this.Apps.filter(app => {

                const active = this.apps.general.enabled && app.enabled && (!app.certsConfig || !!app.certificates?.length)

                if ( !app.active && active && app.onActive ) {

                    if ( app.onActive ) { app.onActive() }

                    if ( app.onUpdate ) {

                        if (!app.timer) { app.timer = this.$timer({ interval: this.refreshInterval, callback: app.onUpdate, autoRestart: true }) }
                        
                        app.timer.start()
                    }
                }

                if ( app.active && !active ) { app.timer?.stop()  }

                return (app.active = active)
            })
        },

        SomeActiveApp() { return !!this.ActivesApp?.length },

        SomeTask() { return this.Tasks.some(t => !t.done) },

        Tasks() {

            const tasks = Object.values(this.tasks ?? {})
            return tasks.sort((a, b) => a.scheduledDate.getTime() - b.scheduledDate.getTime())
        },

        ActiveTasksNow() { return (!this.ReadonlyMode && this.open && !!this.TasksByExecutionDateGroup.today?.length) },

        TasksByExecutionDateGroup() {

            return Object.groupBy(this.Tasks, task => {

                // if ( task.done ) return 'done'

                let scheduledDate = new Date(task.scheduledDate)
                scheduledDate.setHours(0, 0, 0, 0)
                scheduledDate = scheduledDate.getTime()

                if ( scheduledDate <= this.today ) return 'today'

                return this.$formatDate({ date: task.scheduledDate, format: 'dd/mm/yyyy' })
            })
        },
    },

    watch: {

        open() {

            if ( this.open ) localStorage.setItem('is-autopilot-open', true)
            else localStorage.removeItem('is-autopilot-open')
        },

        SomeActiveApp() {},

        ActiveTasksNow(enabled) {
            
            if ( enabled && this.$embedded ) { this.executionTimer.start() }
            else { this.executionTimer.stop() }
        },
    },

    methods: {

        GetTasksGroupName: function(key) {

            if ( key === 'today' ) return 'Robots para hoy'
            else if ( key === 'done' ) return 'Robots terminados'
            return key
        },

        AddAutomation: function(tasks) {

            for (let key in tasks) {

                if (!(key in this.tasks) || this.tasks[key].updatedAt !== tasks[key].updatedAt ) { this.tasks[key] = tasks[key] }
            }
        },

        AddCertificate: function(app) {

            this.modalSlots.certificate = {
                
                title: app.title,
                text: 'Selecciona un certificado para la automatización del proceso',
                headerIcon: { class: 'icon-previous hover:-translate-x-0.5 transition-transform', onclick: () => this.modalSlots.activeSlot = 'modal' },
                footer: [
                    {
                        variant: 'outlined',
                        text: this.$st('general.back'),
                        onclick: () => this.modalSlots.activeSlot = 'modal'
                    },
                    {
                        text: this.$st('general.add'),
                        onclick: () => { this.AddCertificatesToApp(); this.modalSlots.activeSlot = 'modal' }
                    }
                ]
            }

            this.modalSlots.activeSlot = 'certificate'
            this.modalSlots.selectedApp = app
        },

        LaunchNextActiveTask: async function() {

            const nextTask = this.TasksByExecutionDateGroup?.today?.find(task => (!task.done && !task.removing && task.app.active))

            if ( this.executionInProgress || !nextTask ) return

            if ( nextTask.scheduledDate.getTime() <= new Date().getTime() ) {

                let externalResolve
                nextTask.app.timer.waitForIt = new Promise(r => externalResolve = r)
            
                nextTask.executionInProgress = this.executionInProgress = true

                await nextTask.robot?.(nextTask)

                nextTask.executionInProgress = this.executionInProgress = false
                externalResolve()
                store.state.OnAutopilotExecution?.()
            }
        },

        GetScheduleInterval: function(interval = 'days', every = 1) {

            switch(interval) {

                case 'hours': return every === 1 ? 'hora' : 'horas'

                case 'days': return every === 1 ? 'día' : 'días'

                case 'months': return every === 1 ? 'mes' : 'meses'
            }
        },

        // ==========| Certificates |==========

        AddCertificatesToApp: async function() {

            if ( !this.modalSlots.selectedCerts?.length ) return

            const selectedCerts = await UserResourcesManager.methods.GetCertificatesSerialNumber(this.modalSlots.selectedCerts)

            let certificates

            if ( this.modalSlots.selectedApp.certsConfig.multiple && this.modalSlots.selectedApp.certificates?.length ) {

                certificates = [...this.modalSlots.selectedApp.certificates]
                selectedCerts.forEach(cert => { if (!certificates.includes(cert)) { certificates.push(cert) } })

            } else { certificates = selectedCerts }

            this.modalSlots.selectedApp.normalizedCertificates = await UserResourcesManager.methods.SerialNumberToCerts(certificates)
            this.modalSlots.selectedApp.certificates = certificates

            delete this.modalSlots.selectedCerts
        },

        // ==========| Flows Automation |==========

        UpdatedScheduledDate: function({ every, interval = 'days', scheduledDate }) {

            const currentDate = new Date(scheduledDate)
            const now = new Date().getTime()

            while (currentDate.getTime() < now) {

                switch(interval) {

                    case 'hours':

                        currentDate.setHours(currentDate.getHours() + every)
                        break

                    case 'days':

                        currentDate.setDate(currentDate.getDate() + every)
                        break

                    case 'months':

                        currentDate.setMonth(currentDate.getMonth() + every)
                        break
                }
            }

            return currentDate
        },

        GetFlowSchedules: async function() {

            if ( !store.state.currentUser ) return

            this.loading.schedules = true

            const schedules = []

            let query

            if ( this.ReadonlyMode ) { query = `?user=${store.state.currentUser.userData.email}` }

            else {

                const machineHash = await this.$desktopAppComunication("GetMachineHashCode")
                if ( !machineHash ) {

                    // Avisar de que no tienes máquina
                    return
                }

                query = `?machineHash=${machineHash}`
            }

            const res = await flows.getSchedule(query)

            if ( res?.status === 200 ) { schedules.push(...res.data.items) }

            const normalizedSchedules = Object.fromEntries(schedules.map(schedule => {

                const localCustomProcess = LocalFlows[schedule.localProcess] ?? {}

                const automation = {

                    title: schedule.browserFlowName,
                    app: this.apps.robots,
                    canBeRemoved: true,
                    ...schedule,
                    scheduledDate: new Date(schedule.scheduledDate),
                    info: [{ text: schedule.user, icon: 'icon-user', tooltip: `Programado por ${schedule.user}` }],
                    ...localCustomProcess,
                    robot: async (scheduleData) => {

                        const existScheduleResponse = await flows.getScheduleById(scheduleData._id,"?existSchedule=true")

                        if ( existScheduleResponse.status === 200 && !existScheduleResponse.data ) { // Schedule has been removed
                            
                            delete this.tasks[schedule._id]
                            return
                        }

                        let response
                        const flowData = {

                            flowInputs: schedule.flowInputs,
                            flowId: schedule.browserFlow,
                            sendOutputToServer: schedule.sendOutputToServer,
                            browserFlowSchedule: schedule._id
                        }

                        if ( automation.localFlow ) { response = await automation.localFlow(flowData) }
                        else { response = await FlowsController.LaunchFlow(flowData) }

                        if ( response?.status === 299 ) { return }

                        if ( automation.repeat ) {

                            const nextScheduledDate = this.UpdatedScheduledDate(automation).toISOString()
                            const updateResponse = await flows.updateSchedule(schedule._id, `?scheduledDate=${nextScheduledDate}`)

                            if ( updateResponse?.status === 200 ) {

                                scheduleData.scheduledDate = new Date(nextScheduledDate)
                            }

                        } else { await this.DeleteFlowSchedule(scheduleData) }

                        if (automation.automationResponse) automation.automationResponse()

                        return response
                    }
                }

                if ( this.ReadonlyMode && automation.machine ) { automation.info.push({ text: automation.machine.name, icon: 'icon-desktop !text-lg', tooltip: `Programado para ejecutar en ${automation.machine.name}` }) }

                return [schedule._id, automation]

            }))

            if ( this.tasks ) { // Remove robots schedules if doesn't exist in ddbb
                
                Object.values(this.tasks).forEach(async task => {

                    const hasBeenRemovedInDB = !(task._id in normalizedSchedules)
                    const hasBeenUpdated = this.tasks[task._id]?.updatedAt !== task.updatedAt

                    if ( !this.$embedded ) {

                        let logs = await flows.getLogs(`?browserFlowSchedule=${task._id}`)

                        if ( logs?.status === 200 && logs.data ) {

                            logs = Object.values(logs.data.items ?? {})
                            
                            const taskMs = task.scheduledDate.getTime()
                            const log = logs[0] // Latest log

                            if ( log && (hasBeenUpdated || new Date(log.createAt).getTime() >= taskMs) ) { this.AutomationFinishedRemotely(task, log) }
                        }
                    }

                    if (hasBeenRemovedInDB) { delete this.tasks[task._id] }
                })
            }

            this.AddAutomation(normalizedSchedules)

            this.loading.schedules = false
        },

        AutomationFinishedRemotely: function(task, log) {

            if (log) this.$addRobotLogNotification(log)

            if (task.automationResponse) task.automationResponse()
        },

        DeleteFlowSchedule: async function(task) {

            if ( !task?._id ) return
            
            task.removing = true

            await flows.deleteSchedule(task._id)

            task.removing = false

            delete this.tasks[task._id]
        },

        // ==========| Altas y Bajas |==========

        GetAltasBajasForms: async function() {

            const forms = await AltasBajasController.GetForms()

            if ( !forms?.length ) return

            const automation = this.tasks[this.apps.altasbajas.automationID]
            const certificates = [...this.apps.altasbajas.normalizedCertificates]
            const submissions = forms.filter(form => !form.processed)

            if ( !submissions?.length ) return

            const updateData = (data) => {

                const data_ = { data }

                if ( data.certificates?.length ) { data_.info = data.certificates?.map(c => { return { icon: 'icon-certificate text-lg', text: c.subject.CN } }) }

                return data_
            }

            if ( !automation ) {

                const date = new Date()

                const automation = {

                    app: this.apps.altasbajas,
                    description: 'Obtener los formularios de Altas y sincronizar con Sistema RED',
                    scheduledDate: date,
                    ...updateData({ certificates, submissions }),
                    // items: forms.reduce((acc, form) => { if ( !form.processed && form.lastError !== undefined ) { acc.push({ text: form.title }) } return acc }, []),
                    robot: async (scheduleData) => { // eslint-disable-line

                        const response = await AltasBajasController.SyncSubmissions({ flowInputs: scheduleData.data })

                        delete this.tasks[this.apps.altasbajas.automationID]

                        return response
                    }
                }

                this.AddAutomation({ [this.apps.altasbajas.automationID]: automation })
            }

            else if ( !automation.executionInProgress ) { Object.assign(automation, updateData({ certificates, submissions })) }
        },

        // ==========| Notifications Automation |==========

        GetNotificationsSchedules: async function(skippedSchedule) {

            if ( this.apps.notifications.automationID in this.tasks ) return

            const certificates = [...this.apps.notifications.normalizedCertificates]
            const date = new Date()

            if ( skippedSchedule ) { date.setMinutes(date.getMinutes() + 10) }

            const automation = {

                app: this.apps.notifications,
                description: 'Obtener las notificaciones existentes en DEHÚ',
                scheduledDate: date,
                info: certificates?.map(c => { return { icon: 'icon-certificate text-lg', text: c.subject.CN } }),
                data: { certificates },
                robot: async (scheduleData) => { // eslint-disable-line

                    const response = await NotificationsController.GetNotificationsFromDehu(certificates)

                    delete this.tasks[this.apps.notifications.automationID]

                    this.GetNotificationsSchedules(true)

                    return response
                }
            }

            this.AddAutomation({ [this.apps.notifications.automationID]: automation })
        }
    }
}
</script>

<style>
.grow-enter-active,
.grow-leave-active {
    transition: height 150ms, width 200ms, opacity 300ms ease;
}

.grow-enter-from {
    opacity: 0;
    height: 0 !important;
    width: 0 !important;
}

.grow-enter-to {
    opacity: 1;
    height: 100%;
    width: 100%;
}

.grow-leave-from {
    height: 100%;
    width: 100%;
    opacity: 1;
}

.grow-leave-to {
    height: 0 !important;
    width: 0 !important;
    opacity: 0;
}
</style>