<template>

    <div
        class="w-full flex flex-col"
        :class="[this.size, this.variant, {'error': hasError}]">

        <p v-if="label" id="label" class="w-fit text-xs">{{ label }}</p>

        <div class="relative flex flex-col gap-2" tabindex="0" :focus-group="dropdownID" @focus="this.$handleFocusGroup(() => this.showMenu = false)">

            <div
                id="dropdown-header"
                class="relative flex items-center justify-between gap-2 cursor-pointer"
                @click="this.showMenu = !this.showMenu">

                <p class="truncate" :title="selectedItemText">{{ selectedItemText || translate('general.select') }}</p>
                <i class="icon-chevron-down !text-lg"></i>

            </div>

            <div
                v-if="this.showMenu"
                id="dropdown-menu"
                class="w-full top-[calc(100%+6px)] left-0 flex flex-col gap-1 z-10 shadow-weak_material"
                :class="{'absolute animate-dropdown': this.floating}">

                <div
                    id="dropdown-item"
                    v-for="item in this.items" :key="item"
                    class="relative flex items-center gap-2 transition-[background] cursor-pointer"
                    :class="[this.size, this.variant, { 'selected': this.value?.toString() && this.value === item.value }]"
                    @click="this.HandleItemClick(item)">

                    <p>{{ item.label }}</p>

                    <input
                        id="extra-input"
                        v-if="item.input"
                        v-model="item.value"
                        @input="this.value = item.value"
                        type="text"
                        spellcheck="false"
                        class="w-full !cursor-text z-10 outline-none border-none bg-transparent pb-0.5">

                </div>

            </div>

        </div>

        <InfoComponent :data="info" class="mt-1.5"/>

    </div>

</template>

<script>
import BaseInputComponent from './BaseInputComponent.vue'

export default {

    extends: BaseInputComponent,

    props: {

        variant: { type: String, default: 'default' },
        size: { type: String, default: 'md' },
        floating: { type: Boolean }
    },

    computed: {

        selectedItemText() { return this.items.find(i => i.value === this.value)?.label ?? this.value }
    },

    data: function() {

        return {

            showMenu: false,
            dropdownID: Math.floor(Math.random() * 9000) + 1000
        }
    },

    methods: {

        HandleItemClick: function(item) {

            if ( !item.input ) {
                
                this.value = item.value
                this.showMenu = false

            } else { this.value = item.value }
        }
    }
}
</script>

<style scoped>

.lg {
    font-size: 14px;
}

.lg #dropdown-header {
    height: 48px;
    border-radius: 6px;
    padding: 0 16px;
}

.lg #dropdown-menu {
    padding: 8px 0;
    border-radius: 6px;
}

.lg #dropdown-item {
    font-size: 14px;
    padding: 4px 16px;
}

.lg #label {
    margin-bottom: 6px;
}

.md {
    font-size: 14px;
}

.md #dropdown-header {
    height: 40px;
    border-radius: 6px;
    padding: 0 14px;
}

.md #dropdown-menu {
    padding: 6px 0;
    border-radius: 6px;
}

.md #dropdown-item {
    padding: 4px 14px;
}

.md #label {
    margin-bottom: 6px;
}

.sm {
    font-size: 14px;
}

.sm #dropdown-header {
    height: 36px;
    border-radius: 6px;
    padding: 0 14px;
}

.sm #dropdown-menu {
    padding: 6px 0;
    border-radius: 6px;
}

.sm #dropdown-item {
    padding: 2px 14px;
}

.sm #label {
    margin-bottom: 4px;
}

.xs {
    font-size: 12px;
}

.xs #dropdown-header {
    height: 28px;
    border-radius: 4px;
    padding: 0 14px;
}

.xs #dropdown-menu {
    padding: 4px 0;
    border-radius: 4px;
}

.xs #dropdown-item {
    padding: 2px 14px;
}

.xs #label {
    margin-bottom: 4px;
}

/*========== outlined_light ==========*/

.outlined_light {
    color: theme('colors.main.5');
}
.outlined_light #dropdown-header {
    border: 1px solid theme('colors.main.5');
}

.outlined_light.error #dropdown-header {
    border-color: theme('colors.error.100');
}

.outlined_light #dropdown-menu {
    border: 1px solid theme('colors.main.5');
}

.outlined_light #dropdown-item:hover::before {
    content: '';
    position: absolute;
    inset: 0;
    background: theme('colors.main.5');
    opacity: 0.1;
    pointer-events: none;
}

.outlined_light #dropdown-item.selected::before {
    content: '';
    position: absolute;
    inset: 0;
    background: theme('colors.main.5');
    opacity: 0.2;
    pointer-events: none;
}

.outlined_light #extra-input {
    border-bottom: 1px solid theme('colors.main.50');
}

/*========== default ==========*/

.default {
    color: theme('colors.main.100');
}
.default #dropdown-header {
    border: 1px solid transparent;
    background: theme('colors.main.10');
}

.default.error #dropdown-header {
    border-color: theme('colors.error.100');
}

.default #dropdown-menu {
    background: theme('colors.main.10');
}

.default #dropdown-item:hover::before {
    content: '';
    position: absolute;
    inset: 0;
    background: theme('colors.main.50');
    opacity: 0.1;
    pointer-events: none;
}

.default #dropdown-item.selected::before {
    content: '';
    position: absolute;
    inset: 0;
    background: theme('colors.main.50');
    opacity: 0.2;
    pointer-events: none;
}

.default #extra-input {
    border-bottom: 1px solid theme('colors.main.50');
}
</style>