<template>

	<Layout/>

</template>


<script>
import Layout from '@/views/Layout'

export default {

	name: 'App',

	data: function() {

		return {}
	},

	components: { Layout },
}
</script>


<style>

.skeleton-shine-anim {
  background: linear-gradient(110deg, theme('colors.main.50') 8%, theme('colors.main.10') 18%, theme('colors.main.50') 33%);
  background-size: 200% 100%;
  animation: 1.5s skeleton-shine-anim linear infinite;
}

@keyframes skeleton-shine-anim {
  to {
    background-position-x: -200%;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes swipeUp {
  from {
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

/* ---------- GLOBAL OVERLAYS ---------- */

.globalOverlay {
  position: fixed;
  inset: 0;
  background: rgba(1, 26, 88, 0.6);
  z-index: 500;
}

.globalOverlay.center {
  display: flex;
  align-items: center;
  justify-content: center;
}


/* ---------- BUTTONS ---------- */
.generalButton {
  position: relative;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 24px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;
}

.generalTextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  white-space: nowrap;
  background: transparent;
  border: none;
  outline: none;
  color: #FFF;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
}

.generalTextButton:disabled {
  opacity: 0.3;
  pointer-events: none;
}

.generalTextButton:hover {
  text-decoration: underline;
}

.generalButton.primary {
  color: #FFF;
  background: theme('colors.cta.75');
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

  transition: background 0.2s, color 0.2s;
}

.generalButton.secondary {
  background: none;
  border: 1px solid theme('colors.cta.75');
  color: theme('colors.cta.75');
  padding: 0 20px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

  transition: box-shadow 0.3s, color 0.2s;
}

.generalButton.primary:disabled {
  background: theme('colors.cta.50');
  pointer-events: none;
}

.generalButton.secondary:disabled {
  opacity: 0.4;
  pointer-events: none;
}

.generalButton.primary:hover {
  background: theme('colors.cta.100');
}

.generalButton.secondary:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  color: theme('colors.cta.100');
  border-color: theme('colors.cta.100');
}

.generalButton.primary:active {
  background: theme('colors.cta.100');
}

.generalButton.secondary:active {
}


/* ---------- LOADERS ---------- */
.smallLoadingSpinner {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 4px solid transparent;

  animation: rotate 0.8s linear infinite;
}

.tinyLoadingSpinner {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 3px solid transparent;

  animation: rotate 0.8s linear infinite;
}

.bigLoadingSpinner {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 8px solid transparent;

  animation: rotate 0.7s linear infinite;
}

.bigLoadingSpinner.center {
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.bigLoadingSpinner.main, .smallLoadingSpinner.main, .tinyLoadingSpinner.main {
  border-top-color: theme('colors.main.100');
  border-right-color: theme('colors.main.100');
}

.bigLoadingSpinner.wg, .smallLoadingSpinner.wg, .tinyLoadingSpinner.wg {
  border-top-color: #FFF;
  border-right-color: #FFF;
}

.bigLoadingSpinner.cta, .smallLoadingSpinner.cta, .tinyLoadingSpinner.cta {
  border-top-color: theme('colors.cta.100');
  border-right-color: theme('colors.cta.100');
}

.dp__theme_light {
    --dp-background-color: #ffffff;
    --dp-text-color: #011A58;
    --dp-hover-color: #C7E7EE;
    --dp-hover-text-color: #011A58;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #219EBC;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #DC47A9;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #011A58;
    --dp-danger-color: #ff6f60;
    --dp-highlight-color: rgba(25, 118, 210, 0.1);
  }


/* ---------- SVG ---------- */
svg.size-12 { height: 12px; width: 12px; }
svg.size-14 { height: 14px; width: 14px; }
svg.size-16 { height: 16px; width: 16px; }
svg.size-18 { height: 18px; width: 18px; }
svg.size-20 { height: 20px; width: 20px; }
svg.size-24 { height: 24px; width: 24px; }
svg.size-28 { height: 28px; width: 28px; }
svg.size-32 { height: 32px; width: 32px; }

svg.stroke-white path,
.stroke-white svg path,
.stroke-white svg,
svg.stroke-white circle
{ stroke: white; }

svg.stroke-main path,
.stroke-main svg path,
svg.stroke-main circle
{ stroke: theme('colors.main.100'); }

svg.stroke-main-60 path,
.stroke-main-60 svg path,
svg.stroke-main-60 circle
{ stroke: theme('colors.main.60'); }

svg.stroke-main-50 path,
.stroke-main-50 svg path,
svg.stroke-main-50 circle
{ stroke: theme('colors.main.50'); }

svg.stroke-cta path,
.stroke-cta svg path,
svg.stroke-cta circle
{ stroke: theme('colors.cta.100'); }

svg.sw-1 path { stroke-width: 1; }
svg.sw-1_5 path { stroke-width: 1.5; }
svg.sw-2 path { stroke-width: 2; }
svg.sw-3 path { stroke-width: 3; }
</style>