<template>

    <div
        class="w-[300px] flex flex-col gap-8 text-main-100"
        :class="{'bg-main-5 p-3 rounded-xl shadow-material': this.defaultStyle}"
        tabindex="0">

        <div class="flex items-center justify-between">

            <Button
                size="xs"
                variant="default"
                icon="icon-chevron-left"
                :rounded="true"
                @click="this.ToDate({ monthly: -1 })"/>

            <div class="flex items-center text-sm">

                <div
                    class="cursor-pointer py-0.5 px-1 rounded hover:bg-main-10"
                    @click="showMonthSelector = !showMonthSelector; showYearSelector = false"
                    tabindex="0"
                    @focus="this.$handleFocusGroup(() => showYearSelector = showMonthSelector = false)"
                    focus-group="calendar-button">
                    
                    {{ this.$st('utils.months.' + this.currentCalendarDate?.getMonth()) }}

                    <FloatingElement v-if="showMonthSelector" :position="{ top: 4, left: 0, translateY: true }">

                        <div class="max-h-[150px] py-1 bg-main-5 shadow-weak_material rounded overflow-y-auto scroll-main text-xs" focus-group="calendar-button">

                            <div
                                v-for="month, index in 12" :key="month"
                                class="py-1 px-3 hover:bg-main-10 cursor-pointer"
                                @click="this.ToDate({ month: index })">
                                
                                {{ this.$st('utils.months.' + index) }}
                            </div>

                        </div>

                    </FloatingElement>
                
                </div>

                <div
                    class="cursor-pointer py-0.5 px-2 rounded hover:bg-main-10"
                    @click="showYearSelector = !showYearSelector; showMonthSelector = false"
                    tabindex="0"
                    @focus="this.$handleFocusGroup(() => showYearSelector = showMonthSelector = false)"
                    focus-group="calendar-button">
                    
                    {{ this.currentCalendarDate?.getFullYear() }}

                    <FloatingElement v-if="showYearSelector" :position="{ top: 4, left: 0, translateY: true }">

                        <div class="max-h-[150px] py-1 bg-main-5 shadow-weak_material rounded overflow-y-auto scroll-main text-xs" focus-group="calendar-button">

                            <div
                                v-for="year in this.years" :key="year"
                                @click="this.ToDate({ year })"
                                class="py-1 px-3 hover:bg-main-10 cursor-pointer">
                                
                                {{ year }}
                            </div>

                        </div>

                    </FloatingElement>
                
                </div>

            </div>

            <Button
                size="xs"
                variant="default"
                icon="icon-chevron-right"
                :rounded="true"
                @click="this.ToDate({ monthly: 1 })"/>

        </div>

        <div class="grid grid-cols-7 grow text-main-100 text-xs select-none">

            <div
                v-for="weekday in this.weekdays" :key="weekday"
                class="flex items-center justify-center text-main-50 mb-3">
                
                {{ weekday }}

            </div>

            <CalendarDay
                v-for="day in this.currentCalendarSchema?.previousDays" :key="day"
                :day="day"
                :state="this.DateStateHandler(day, -1)"
                :events="this.GetDayEvents(day, -1)"
                :adjacent="true"
                @click="this.OnClickDay(day, -1)"/>

            <CalendarDay
                v-for="day in this.currentCalendarSchema?.days" :key="day"
                :day="day"
                :state="this.DateStateHandler(day, 0)"
                :events="this.GetDayEvents(day, 0)"
                @click="this.OnClickDay(day, 0)"/>

            <CalendarDay
                v-for="day in this.currentCalendarSchema?.nextDays" :key="day"
                :day="day"
                :state="this.DateStateHandler(day, 1)"
                :events="this.GetDayEvents(day, 1)"
                :adjacent="true"
                @click="this.OnClickDay(day, 1)"/>

        </div>

    </div>
    
</template>

<script>
import Button from '@/slango-multiverse/components/inputs/Button'
import FloatingElement from '@/slango-multiverse/components/FloatingElement'
import CalendarDay from '@/components/CalendarDay'
import { GoogleCalendarEvents } from '@/helpers/APIconnection'

import BaseInputComponent from '@/slango-multiverse/components/inputs/BaseInputComponent.vue'

export default {

    extends: BaseInputComponent,

    props: {

        iso: { type: Boolean, default: true }, // Week starts on Monday
        range: { type: Boolean, default: false }, // Range selection
        googleCalendars: { type: Array },
        defaultStyle: { type: Boolean, default: true },
        intervalTime: { type: Number },
        repeatInterval: { type: String, default: 'days' },
        modelValue: { default: (this_) => { return this_.range ? [] : undefined} },
    },

    components: { Button, CalendarDay, FloatingElement }, // eslint-disable-line

    data: function() {

        return {

            currentCalendarDate: undefined, // Current date showing
            showMonthSelector: false,
            showYearSelector: false,

            currentCalendarSchema: undefined, // Days of current month in calendar
            weekdays: this.GetWeekdaysInitials(), // Weekdays initials
            events: [],
        }
    },

    async created() {

        if ( this.googleCalendars ) {

            const events = await Promise.all(this.googleCalendars.map(async (id) => {

                return new Promise(r => {

                    GoogleCalendarEvents(id).then(gr => {

                        const items = gr.data?.items?.map(e => {

                            return {

                                start: new Date(e.start.dateTime ?? e.start.date).getTime(),
                                end: new Date(e.end.dateTime ?? e.end.date).getTime(),
                                dayStart: new Date(e.start.dateTime ?? e.start.date).setHours(0, 0, 0, 0),
                                dayEnd: new Date(e.end.dateTime ?? e.end.date).setHours((e.end.date ? -24 : 0), 0, 0, 0),
                                creator: e.creator,
                                href: e.htmlLink,
                                summary: e.summary,
                                description: e.description
                            }

                        })

                        r(items)
                    })
                })
            }))

            this.events.push(...events.flat())
        }

        this.GetCalendar()
    },

    computed: {

        years() { return Array.from({length: (new Date()).getFullYear() - 1899}, (_, index) => 1900 + index).reverse() },

        today() { 
            
            const today = new Date()
            today.setHours(0, 0, 0, 0)
            return today.getTime()
        },

        eventsObject() {

            if ( !this.events?.length ) return {}

            const events = {}

            this.events.forEach(e => {

                if ( e.dayStart in events ) { events[e.dayStart].push(e) }
                else { events[e.dayStart] = [e] }
            })

            return events
        }
    },

    methods: {

        GetCalendar: function(date) {

            const initDate = date ? new Date(date) : new Date()

            const firstDayOfMonth = new Date(initDate.getFullYear(), initDate.getMonth(), 1)
            const lastDayOfMonth = new Date(initDate.getFullYear(), initDate.getMonth() + 1, 0)

            this.currentCalendarDate = firstDayOfMonth

            const days = []
            for (let i = 1; i <= lastDayOfMonth.getDate(); i++) { days.push(i) }
            
            let previousDaysCount = firstDayOfMonth.getDay()
            let nextDaysCount = 6 - lastDayOfMonth.getDay()

            if ( this.iso ) {

                previousDaysCount--
                nextDaysCount++

                if ( previousDaysCount === -1 ) { previousDaysCount = 6 }
                if ( nextDaysCount === 7 ) { nextDaysCount = 0 }
            }

            const previousDays = []
            const nextDays = []

            if ( previousDaysCount ) {

                const previousMonthLastDay = new Date(initDate.getFullYear(), initDate.getMonth(), 0).getDate()
                for (let i = previousMonthLastDay - (previousDaysCount - 1); i <= previousMonthLastDay; i++) { previousDays.push(i) }
            }

            if ( nextDaysCount ) {

                for (let i = 1; i <= nextDaysCount; i++) { nextDays.push(i) }
            }

            this.currentCalendarSchema = { previousDays, days, nextDays }
        },

        GetWeekdaysInitials: function() {

            let weekdays = this.$st('utils.weekdays_initials').split(' ')

            if ( !this.iso ) { weekdays.unshift(weekdays.pop()) }

            return weekdays
        },

        OnClickDay: function(day, adjacentMonth = 0) {

            if ( this.disabled ) { return }

            const selected = new Date(this.currentCalendarDate.getFullYear(), this.currentCalendarDate.getMonth() + adjacentMonth, day).getTime()

            if ( this.range ) {

                if ( this.value[0] && this.value[1] ) { this.value = [] }
                
                let selection = this.value[0] ? 1 : 0

                this.value[selection] = selected

                if ( this.value[0] > this.value[1] ) { this.value.reverse() }
            }

            else { this.value = selected }
        },

        GetDayEvents: function(day, adjacentMonth = 0) {

            const date = new Date(this.currentCalendarDate.getFullYear(), this.currentCalendarDate.getMonth() + adjacentMonth, day).getTime()
            return this.eventsObject[date]
        },

        DateStateHandler: function(day, adjacentMonth = 0) {
            
            if ( !this.currentCalendarDate ) { return }

            const state = {}

            const date = new Date(this.currentCalendarDate.getFullYear(), this.currentCalendarDate.getMonth() + adjacentMonth, day).getTime()

            if ( this.range ) {

                if ( !this.value ) { return state }

                if ( date === this.value[0] ) { state.selected = true, state.start = true }
                else if ( date === this.value[1] ) { state.selected = true, state.end = true }

                if ( this.value[0] !== this.value[1] && date >= this.value[0] && date <= this.value[1] ) { state.between = true }
            }

            else if ( this.value === date ) { state.selected = true }

            else if ( this.intervalTime && this.value ) {

                if ( this.IsTodayARepeatInterval(new Date(date), new Date(this.value), this.intervalTime, this.repeatInterval) ) state.interval = true
            }

            if ( this.today === date ) { state.today = true }

            return state
        },

        ToDate: function(args) {

            const date = new Date(this.currentCalendarDate)

            if ( args.month !== undefined ) { date.setMonth(args.month) }
            else if ( args.monthly ) { date.setMonth(date.getMonth() + args.monthly) }

            if ( args.year ) { date.setFullYear(args.year) }

            this.GetCalendar(date)
        },

        IsTodayARepeatInterval: function(date, from, intervalTime, repeatInterval = 'days') {

            date = new Date(date)
            from = new Date(from)
            date.setHours(0, 0, 0, 0)
            from.setHours(0, 0, 0, 0)

            if ( repeatInterval === 'days' ) {

                const daysUntil = Math.ceil((date.getTime() - from.getTime()) / 86400000)

                if ( daysUntil >= 0 && (daysUntil % intervalTime) === 0 ) return true
            }

            else if ( repeatInterval === 'months' ) {

                const yearsBetween = date.getFullYear() - from.getFullYear()

                const monthsBetween = (date.getMonth() + (yearsBetween * 12)) - from.getMonth()

                if ( monthsBetween > 0 && (monthsBetween % intervalTime) === 0 ) {

                    const getNumberOfDays = (d) => new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate()

                    const currentDateDays = getNumberOfDays(date)

                    if ( from.getDate() === date.getDate() || ((from.getDate() > currentDateDays) && date.getDate() === currentDateDays) ) return true
                }
            }

            return false
        }
    }
}
</script>