<template>

    <Teleport to="body">

        <div class="fixed z-[500] outline-none animate-dropdown flex" :class="config.class" :focus-group="this.useFocusGroup ?? 'default-focus-group'" tabindex="0" :style="this.style" ref="element">
            
            <slot id="slot"/>

        </div>

    </Teleport>

</template>

<script>

export default {

    props: {

        config: { type: Object, default: () => { return {} } },
        outXConfig: { type: Object },
        outYConfig: { type: Object },
        useFocusGroup: { type: String },
        
        position: { type: Object, default: () => { return { top: 4, left: 0, translateY: true } } }, // top | bottom | left | right | translateY | translateX | centerY | centerX | worldCenterY | worldCenterX
        stretch: { type: Boolean },
        boundaries: { type: Number, default: 8 },
        minHeight: { type: Number, default: 100 },
        minWidth: { type: Number, default: 100 },
    },

    computed: {

        NormalizedPosition() {

            const position = { ...this.position }
            
            position.offsetVertical = position.offsetVertical ?? 'top' in position ? 'top' : 'bottom'
            position.offsetHorizontal = position.offsetHorizontal ?? 'left' in position ? 'left' : 'right'

            position.top = position.top ?? position.bottom
            position.bottom = position.bottom ?? position.top
            position.left = position.left ?? position.right
            position.right = position.right ?? position.left

            return position
        }
    },

    data: function() {

        return {

            anchorElement: undefined,
            element: undefined,
            style: { transition: 'top 50ms, bottom 50ms, left 50ms, right 50ms' },
        }
    },

    unmounted() { clearInterval(this.interval) },
    
    async mounted() {

        clearInterval(this.interval)

        this.element = this.$refs.element
        this.anchorElement = this.$el.parentElement

        this.CalculatePosition()

        this.interval = setInterval(() => this.CalculatePosition(), 250)
    },

    methods: {

        NormalizeConfigData: function(data) {

            return {

                y: Object.assign({ translate: data.translateY, center: data.centerY, worldCenter: data.worldCenterY }, 'top' in data
                    ? { offsetOrigin: 'top', offset: data.top }
                    : { offsetOrigin: 'bottom', offset: data.bottom }),

                x: Object.assign({ translate: data.translateX, center: data.centerX, worldCenter: data.worldCenterX }, 'left' in data
                    ? { offsetOrigin: 'left', offset: data.left }
                    : { offsetOrigin: 'right', offset: data.right }),
            }
        },

        CalculatePosition: function() {

            this.anchorElementRect = this.anchorElement.getBoundingClientRect()
            this.elementRect = this.element.getBoundingClientRect()

            if ( this.stretch ) { this.element.style.width = this.anchorElementRect.width + 'px' }

            this.style.top = this.style.bottom = this.style.left = this.style.right = 'auto'

            Object.assign(this.style, this.GetYAxisPosition(this.NormalizedPosition.offsetVertical))
            Object.assign(this.style, this.GetXAxisPosition(this.NormalizedPosition.offsetHorizontal))
        },

        GetYAxisPosition: function(offsetOrigin, alternateIfLackOfSpace = true) {

            if ( this.NormalizedPosition.worldCenter ) { return (window.innerHeight / 2) - (this.elementRect.height / 2) }
            else if ( this.NormalizedPosition.centerY ) { return this.anchorElementRect.top + (this.anchorElementRect.height / 2) - (this.elementRect.height / 2) }

            let position = 0

            if ( this.NormalizedPosition.translateY ) { position = this.anchorElementRect.height }

            if ( offsetOrigin === 'top' ) {
                
                position += this.anchorElementRect.top + this.NormalizedPosition.top

                const heightGap = window.innerHeight - position - this.boundaries

                if ( alternateIfLackOfSpace && heightGap < this.minHeight ) { return this.GetYAxisPosition('bottom', false) }

                return { top: position + 'px', maxHeight: heightGap + 'px' }
            }

            if ( offsetOrigin === 'bottom' ) {
                
                position += window.innerHeight - this.anchorElementRect.bottom + this.NormalizedPosition.bottom

                const heightGap = window.innerHeight - position - this.boundaries

                if ( alternateIfLackOfSpace && heightGap < this.minHeight ) { return this.GetYAxisPosition('top', false) }

                return { bottom: position + 'px', maxHeight: heightGap + 'px' }
            }
        },

        GetXAxisPosition: function(offsetOrigin, alternateIfLackOfSpace = true) {

            if ( this.NormalizedPosition.worldCenter ) { return (window.innerWidth / 2) - (this.elementRect.width / 2) }
            else if ( this.NormalizedPosition.centerX ) { return this.anchorElementRect.left + (this.anchorElementRect.width / 2) - (this.elementRect.width / 2) }

            let position = 0

            if ( this.NormalizedPosition.translateX ) { position = this.anchorElementRect.width }

            if ( offsetOrigin === 'left' ) {
                
                position += this.anchorElementRect.left + this.NormalizedPosition.left

                const widthGap = window.innerWidth - position - this.boundaries

                if ( alternateIfLackOfSpace && widthGap < this.minWidth ) { return this.GetXAxisPosition('right', false) }

                return { left: position + 'px', maxWidth: widthGap + 'px' }
            }
            
            if ( offsetOrigin === 'right' ) {
                
                position += window.innerWidth - this.anchorElementRect.right + this.NormalizedPosition.right

                const widthGap = window.innerWidth - position - this.boundaries

                if ( alternateIfLackOfSpace && widthGap < this.minWidth ) { return this.GetXAxisPosition('left', false) }

                return { right: position + 'px', maxWidth: widthGap + 'px' }
            }
        },

        // IsOutOfWidth: function(offsetX) {

        //     const widthStyle = {}

        //     if ( this.boundaries.minWidth === 0 ) { widthStyle['min-width'] = 'fit-content' }

        //     const minWidth = this.boundaries.minWidth

        //     const finalSize = offsetX + this.elementRect.width
        //     return finalSize < 0 || finalSize > window.innerWidth
        // },

        // IsOutOfHeight: function(offsetY) {

        //     const finalSize = offsetY + this.elementRect.height
        //     return finalSize < 0 || finalSize > window.innerHeight
        // }
    },

}

</script>