<template>

    <div class="h-fit flex gap-4 justify-between">

        <Calendar
            class="h-fit w-[300px]"
            :defaultStyle="false"
            :repeatInterval="value.interval"
            :intervalTime="value.repeat && value.every ? value.every : undefined"
            v-model="value.date"/>

        <div class="w-px bg-main-100/10 shrink-0"/>

        <div class="grow max-w-[160px] flex flex-col gap-10">

            <div class="flex items-center gap-3 mx-auto">
                
                <RangeSelector
                    v-model="value.hour"
                    :range="[0, 23]"
                    :vertical="true"
                    :normalize="(value) => value?.toString().padStart(2, 0)"/>

                <p>:</p>

                <RangeSelector
                    v-model="value.minute"
                    :range="[0, 59]"
                    :vertical="true"
                    :normalize="(value) => value?.toString().padStart(2, 0)"/>

            </div>

            <!-- <div class="bg-second-50/40 rounded py-2 px-3 border border-second-100/10 text-xs">{{ this.$formatDate({ date: new Date(), format: 'dd/mm/yyyy hh:mm', div: '.' }) }}</div> -->

            <div class="flex flex-col gap-3 border-l-2 border-second-50 pl-4">

                <div class="flex items-center gap-3">

                    Repetir
                    <Toggle v-model="value.repeat" @click="value.repeat = !value.repeat"/>

                </div>

                <div class="flex items-center gap-2.5" :class="{'opacity-50 pointer-events-none': !value.repeat}">

                    Cada

                    <span>

                        <InputField v-model="value.every" size="xs" placeholder="" type="number"/>

                    </span>

                    <div
                        class="flex items-center gap-1.5 cursor-pointer"
                        tabindex="0"
                        @focus="this.$handleFocusGroup(() => showIntervalSelector = false)"
                        focus-group="scheduler-interval-group"
                        @click="showIntervalSelector = !showIntervalSelector">

                        {{ intervalTime[value.interval] }}
                        <i class="icon-chevron-down text-2xs"/>

                        <FloatingElement v-if="showIntervalSelector" :position="{ top: 4, right: 0, translateY: true }">

                            <div class="py-1 bg-main-10 rounded shadow-weak_material" focus-group="scheduler-interval-group">

                                <p
                                    v-for="i, key in intervalTime" :key="i"
                                    class="px-3 py-1 hover:bg-main-50/10 text-xs cursor-pointer"
                                    :class="{'hidden': key === value.interval}"
                                    @click="value.interval = key">
                                    
                                    {{ i }}
                                </p>
                                
                            </div>

                        </FloatingElement>

                    </div>

                </div>
                
            </div>

        </div>

    </div>

</template>

<script>
import BaseInputComponent from '@/slango-multiverse/components/inputs/BaseInputComponent.vue'
import Calendar from '@/components/Calendar'
import FloatingElement from '@/slango-multiverse/components/FloatingElement'
import Button from '@/slango-multiverse/components/inputs/Button'
import Toggle from '@/slango-multiverse/components/inputs/Toggle'
import InputField from '@/slango-multiverse/components/inputs/InputField'
import RangeSelector from '@/slango-multiverse/components/inputs/RangeSelector.vue'

export default {

    extends: BaseInputComponent,

    components: { BaseInputComponent, FloatingElement, Calendar, Button, Toggle, InputField, RangeSelector }, // eslint-disable-line

    props: { onSchedule: Function },

    data: function() {

        return {

            loading: false,

            showIntervalSelector: false,

            intervalTime: { hours: this.$st('utils.hour', 2), days: this.$st('utils.day', 2), months: this.$st('utils.month', 2) }
        }
    },

    watch: {

        value: {

            handler() {

                if ( !this.value ) return

                let normalizedDate = new Date(this.value.date)
                normalizedDate.setHours(this.value.hour)
                normalizedDate.setMinutes(this.value.minute)

                const localeDateString = normalizedDate.toLocaleString()
                const dateISOString = normalizedDate.toISOString()

                if ( this.value.dateISOString !== dateISOString ) { Object.assign(this.value, { localeDateString, scheduledDate: normalizedDate, dateISOString }) }
            
            }, deep: true
        }
    },

    created() {

        this.value = {

            every: 1,
            interval: 'days',
            hour: 0,
            minute: 0
        },

        this.value.date = new Date().setHours(0, 0, 0, 0)
        this.value.hour = new Date().getHours()
    },

    methods: {}
}
</script>